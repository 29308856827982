<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
			<el-form :inline="true">
                <el-form-item>
					<toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
				</el-form-item>
            </el-form>
        </el-col> 
        <el-table
			:data="slotPositionList"
			highlight-current-row
			@current-change="selectCurrentRow"
			v-loading="listLoading"
			@selection-change="selsChange"
			style="width: 100%"
            border
		>
            <el-table-column prop="slotName" label="槽位名称"  align="center"></el-table-column>
            <el-table-column  label="颜色" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag  :color="scope.row.slotColor"  effect="dark"
                    type="info"  disable-transitions   >{{ scope.row.slotColor }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="slotQuestion" label="缺少槽位信息时的提问"  align="center"></el-table-column>
            <el-table-column prop="createUserName" label="添加人" width="150" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" width="180" align="center"></el-table-column>
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button  type="text" size="small" @click.native="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" size="small" @click.native="deleteSlotFunction(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-col :span="24" class="pageBar" align="center">
			<el-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pages.pageIndex"
			:page-sizes="pages.pageArr"
			:page-size="pages.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="pages.dataCount"
			>
			</el-pagination>
        </el-col>
        <el-dialog :title="isAdd?'新增槽位':'编辑槽位'"  :visible.sync="slotDialogFormVisible"  v-model="slotDialogFormVisible" 
        :close-on-click-modal="false" :before-close="closeSlot">
            <el-form :model="slotForm" ref="slotForm" :rules="slotFormRules"  label-width="160px" 
            label-position="right" style="width: 90%;">
                <el-form-item label="槽位名称:" prop="slotName" placeholder="请输入槽位名称">
                    <el-input v-model="slotForm.slotName"></el-input>                   
                </el-form-item>
                <el-form-item label="颜色:" prop="slotColor" placeholder="请选择颜色">
                    <el-color-picker v-model="slotForm.slotColor"></el-color-picker>
                </el-form-item>  
                <el-form-item label="缺少槽位信息时的提问:"  placeholder="请输入缺少槽位信息时的提问">
                    <el-input v-model="slotForm.slotQuestion"></el-input>                   
                </el-form-item>              
            </el-form>
            <div slot="footer" class="dialog-footer" label-position="center" >
                <el-button @click.native="closeSlot">取 消</el-button>
                <el-button type="primary" @click.native="saveSlotFunction" :loading="addSlotLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";    
import {
    getSlotListPage,
    deleteSlot,
    saveSlot,
    editSlot
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
    components: { Toolbar },
    data() {
        return {
            utils: '',
            slotPositionList:[],
            listLoading: false,
            currentRow: null,
             //列表选中列
			sels: [],
            //增删改查按钮
			buttonList: [],
            //关于分页的obj
			pages: {
				pageSize: 20, //默认每页条数
				pageArr: [10, 20, 30, 40], //默认每页条数选择
				pageIndex: 1, //默认进入页
				dataCount: 20, //默认总页数
			},
            formLabelWidth: '120px',
            deleteSlotLoading:false,
            slotDialogFormVisible:false,
            addSlotLoading: false,
            slotFormRules: {
                slotName: [
					{required: true, message: '请输入槽位名称', trigger: 'blur'}
				],
				slotColor: [
					{required: true, message: '请选择槽位颜色', trigger: 'change'}
				]				
			},
            slotForm: {
                slotId: 0,
                slotName: "",
                slotQuestion:'',
                slotColor: ''
            },
            isAdd:true
        }
    },
    methods: {
        // 清空筛选框内容，重新获取数据
        clearContent(){
			this.getListData();
		},
        //当前行发生变化时
		selectCurrentRow(val) {
			this.currentRow = val;
		},
        //选项发生变化时
		selsChange: function (sels) {
			this.sels = sels;
		},
        getListData() {
            this.listLoading = true;
            var params={
                pageIndex: this.pages.pageIndex,
				pageSize: this.pages.pageSize,
            };
            getSlotListPage(params).then((res) => {
                this.listLoading = false;
				this.slotPositionList = res.data.response.data;				

				this.pages.dataCount = res.data.response.dataCount;
				this.pages.pageIndex = res.data.response.pageIndex;
				if (res.data.response.pageSize > 0) {
					this.pages.pageSize = res.data.response.pageSize;
				}
			});
        },
        callFunction(item) {
            this[item.func].apply(this, item);
        },
        //分页
        handleCurrentChange(val) {
			// if(this.filters.name != '' || this.filters.selectc != false){
			//   this.pages.pageIndex = 1
			// }else{
				this.pages.pageIndex = val;
			// }      
			this.getListData();
		},
        handleSizeChange(val) {
			this.pages.pageSize = val;     
			this.getListData();
		},
        //删除槽位
        deleteSlotFunction(val){
            this.$confirm('确定删除吗？', '提示', {}).then(() => {
                this.deleteSlotLoading = true;
                let params ={
                    slotId:val.slotId
                }
                deleteSlot(params).then((res)=>{
                    if (util.isEmt.format(res)) {
                        this.deleteSlotLoading = false;
                        return;
                    }
                    if (res.data.success) {
                        this.deleteSlotLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'success'
                        });
                        this.getListData(); 
                    }
                    else {
                        this.deleteSlotLoading = false;
                        this.$message({
                            message: res.data.message,
                            type: 'error'
                        });
                    }
                });
            });
        },
        handleAdd(){
            // this.addSlotLoading=true;
            this.slotDialogFormVisible =true;
            this.slotForm.slotId=null;
            this.slotForm.slotName="";
            this.slotForm.slotQuestion="";
            this.slotForm.slotColor=null;
            this.isAdd=true;
        },
        handleEdit(val){
            // this.addSlotLoading=true;
            this.slotDialogFormVisible =true;
            this.slotForm.slotId=val.slotId;
            this.slotForm.slotName=val.slotName;
            this.slotForm.slotQuestion=val.slotQuestion;
            this.slotForm.slotColor=val.slotColor;
            this.isAdd=false;
        },
        closeSlot:function(){
            var _this = this;
            _this.addSlotLoading = false;
            this.slotDialogFormVisible =false;
            _this.slotForm.slotId=null;
            _this.slotForm.slotName="";
            _this.slotForm.slotQuestion="";
            _this.slotForm.slotColor=null;
        },
        //编辑保存槽位
        saveSlotFunction(){
            var _this = this;
            this.$refs.slotForm.validate((valid) => {
				if (valid) {
            //         this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        this.addSlotLoading=false;
                        let params = Object.assign({}, this.slotForm); 
                        if(!_this.isAdd){
                            editSlot(params).then((res) => {
                                if (util.isEmt.format(res)) {
                                    this.addSlotLoading = false;
                                    return;
                                }
                                if (res.data.success) {
                                    this.addSlotLoading = false;
                                    this.slotDialogFormVisible =false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getListData(); 
                                    // this.getDataPermissions();
                                }
                                else {
                                    this.addSlotLoading = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'error'
                                    });
                                }
						    });
                        }else {
                            saveSlot(params).then((res) => {
                                if (util.isEmt.format(res)) {
                                    this.addSlotLoading = false;
                                    return;
                                }
                                if (res.data.success) {
                                    this.addSlotLoading = false;
                                    this.slotDialogFormVisible =false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'success'
                                    });
                                    this.getListData(); 
                                    // this.getDataPermissions();
                                }
                                else {
                                    this.addSlotLoading = false;
                                    this.$message({
                                        message: res.data.message,
                                        type: 'error'
                                    });
                                }
						    });
                        }
                        
        //             });
                }
            });
        }
   },
    mounted() {
        this.getListData();    
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
}
</script>

<style lang="stylus" scoped>
::v-deep .dialog-footer {
    text-align: center;
}

.el-tag--dark{
    border:none;
}
</style>